@tailwind base;
@tailwind components;
@tailwind utilities;



/* @keyframes scrollx {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scrollx {
  display: flex;
  flex-direction: row;
  width: 1500px;
  overflow: hidden;
  animation: scrollx 20s linear infinite;

   border: 2px solid red; 

}

.scrollx .testimonial-card {
  flex: 0 0 auto;
  object-fit: contain;
  width: 450px;
  height: 100%;
  margin-right: 20px; 
  

}
.group{
  display: grid;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding-left: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
  margin-right: 200px; 

} */

@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.animate-vibrate {
  animation: vibrate 0.3s linear;
}

.hover {
  @apply  hover:bg-white hover:py-1 px-3  hover:rounded-lg hover:text-black hover:font-semibold ;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* DatePicker */

.react-datepicker {
  font-size: 1.2em; 
}

.react-datepicker__header {
  font-size: 1.1em; 
}

.react-datepicker__month {
  font-size: 1.1em; 
  margin-bottom: 2px;
}

.react-datepicker__day {
  width: 4em; 
}

.react-datepicker__time-container {
  font-size: 1.1em; 
}

/* 
.react-datepicker__time-list-item {
  height: 3em; 
  line-height: 3em;
} */
.text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

/* blog.css */
.tweet-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.tweet-container > div {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  display: inline-block;
  width: 100%;
}

.tweet-container > div > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.tweet-container > div > div > div {
  flex-grow: 1;
}

.tweet-container > div:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .tweet-container {
    grid-template-columns: 1fr;
  }
}

.tweet-container > div > div > div:not(:last-child) {
  flex-grow: 1;
}

.tweet-container > div > div > div:last-child {
  margin-top: auto;
}

.loader-dashboard {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.custom-tooltip {
  font-size: 14px;
}
